import request from 'utils/request';

export const api = {
  OFFER_LIST: '/api/offer/list',
  OFFER_STATUS_UPDATE: '/api/offer/updateStatus',
  OFFET_TEST_UPDATE: '/api/offer/updateOfferTest',
  // APPROVAL_AFFS: '/api/offer/approvalAffs',
  APPROVAL_AFFS: '/api/offer/approvalEventAffs',
  APPROVAL_AFF_UPDATE: '/api/offer/approvalEventAff/update',
  APPROVAL_AFF_SIGLEINFO_GET: '/api/offer/approvalEventAff/get',
  BATCH_APPROVAL_AFFS: '/api/offer/batchApprovalAffs',
  // OFFER_MOTIFYS: '/api/offer/motifys',
  OFFER_MOTIFYS: '/api/offer/batchHold',
  OFFER_BATCH_ACTIVE: '/api/offer/batchActive ',
  IDS: '/api/offer/selectPids',
  PBDomains: '/api/report/common/domain/list',
  UPDATA_OFFER_LINK_TYPE: '/api/offer/updateOfferLinkType',
  OFFER_REPLACE_PB: '/api/offer/batchUpdateOfferSource',
  FILE_TEMPLATE: '/api/file/import/offer',
  OFFER_BATCH_IMPORT: '/api/file/download',
  batch_offer_source_link: '/api/offer/batchUpdateOfferSourceV2',
  batch_offer_impression: '/api/offer/batchImpressionSource',
  OFFER_BATCH_OFFER_EVENTPAYOUTINFO: '/api/offer/batchEventInfo',
  SELECT_OFFER_BY_NAME: '/api/offer/selectByName',
  UPDATA_OFFER_PAYOUT_TYPE: '/api/offer/updateOfferPayoutType',
};

export function getOfferList(query) {
  return request({
    url: api.OFFER_LIST,
    method: 'get',
    params: query,
  });
}

export function updateOfferStatus(offerId) {
  return request({
    url: api.OFFER_STATUS_UPDATE,
    method: 'put',
    data: {
      offerId,
    },
  });
}
export function updateOfferTest(offerId) {
  return request({
    url: api.OFFET_TEST_UPDATE,
    method: 'put',
    data: {
      offerId,
    },
  });
}
export function approvalAfffs(data) {
  return request({
    url: api.APPROVAL_AFFS,
    method: 'post',
    data,
  });
}

export function batchApprovalAffs(data) {
  return request({
    url: api.BATCH_APPROVAL_AFFS,
    method: 'post',
    data,
  });
}

export function batchHold(data) {
  return request({
    url: api.OFFER_MOTIFYS,
    method: 'put',
    data,
  });
}

export function batchActive(data) {
  return request({
    url: api.OFFER_BATCH_ACTIVE,
    method: 'put',
    data,
  });
}

// 查询pid
export function getPidsList(query) {
  return request({
    url: api.IDS,
    method: 'get',
    params: query,
  });
}

// 更新offer link type
export function handleUpdataOfferLinkType(query) {
  return request({
    url: api.UPDATA_OFFER_LINK_TYPE,
    method: 'get',
    params: query,
  });
}

// 查询pbdomain
export function getPbDomainList(query) {
  return request({
    url: api.PBDomains,
    method: 'get',
    params: query,
  });
}

// 批量更新PB
export function handleReplacePb(data) {
  return request({
    url: api.OFFER_REPLACE_PB,
    method: 'post',
    data,
  });
}

export function handleBatchImport(query) {
  return request({
    url: api.OFFER_BATCH_IMPORT,
    method: 'get',
    params: query,
    responseType: 'blob',
    headers: {
      responseType: 'blob',
    },
  });
}

export function batchOfferSourceLink(data) {
  return request({
    url: api.batch_offer_source_link,
    method: 'put',
    data,
  });
}
export function batchOfferImpressionLink(data) {
  return request({
    url: api.batch_offer_impression,
    method: 'put',
    data,
  });
}

export function handleBatchOfferEventPayoutInfo(data) {
  return request({
    url: api.OFFER_BATCH_OFFER_EVENTPAYOUTINFO,
    method: 'post',
    data,
  });
}

export function handleApproveUpdate(data) {
  return request({
    url: api.APPROVAL_AFF_UPDATE,
    method: 'post',
    data,
  });
}

export function fetchSingleApproveInfo(query) {
  return request({
    url: api.APPROVAL_AFF_SIGLEINFO_GET,
    method: 'get',
    params: query,
  });
}
// 根据offerNmae 查询offerId
export function getOfferByName(query) {
  return request({
    url: api.SELECT_OFFER_BY_NAME + '?offerName=' + query,
    method: 'get',
  });
}

export function handleOfferPayoutType(data) {
  return request({
    url: api.UPDATA_OFFER_PAYOUT_TYPE,
    method: 'post',
    data,
  });
}
