<template>
  <div class="advReport">
    <!-- <div class="main-Title bgff"><h2>Adv/Source Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="advReport" size="mini">
          <el-row>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="OfferId(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="advReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" style="height: 34px;">
              <el-form-item label="Source(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <group-select
                      v-model="advReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      reserve-keyword
                      :loading="sourceLoading"
                      clearable
                      filterable
                      collapse-tags
                      size="mini"
                      placeholder="Please select"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Countries" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="advReport.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Package" label-width="1.2rem" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="advReport.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="TimeZone" label-width="80px" class="mb5">
                <el-select v-model="advReport.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="8"
              :lg="8"
              :xl="6"
              :style="{ height: fullWidth < 1920 ? '35px' : '34px' }"
            >
              <el-form-item label="Interval" class="mb5" label-width="70px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Month" class="mb0">
                      <el-checkbox v-model="monthChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Date" class="mb0">
                      <el-checkbox v-model="dateChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="Hour" class="mb0">
                      <el-checkbox v-model="hourChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" style="height: 34px;">
              <el-form-item label="Date" label-width="55px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="sourceManagers(s)" label-width="140px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="advReport.sourceManagers"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in sourceManagers"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="sourceManagersChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="pid(s)" label-width="50px" class="mb5">
                <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                  <el-select
                    v-model="advReport.pids"
                    multiple
                    filterable
                    collapse-tags
                    placeholder="Please select"
                    class="w100"
                  >
                    <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                  <el-checkbox v-model="pidChecked"></el-checkbox>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="offer Link Type" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="advReport.offerTypes"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="rt" value="rt"></el-option>
                      <el-option label="ua" value="ua"></el-option>
                      <!-- <el-option label="unKnow" value="unKnow"></el-option> -->
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerLinkTypeChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Group by" label-width="80px" class="mb5">
                <el-col :lg="20" :md="20">
                  <el-form-item label="eventName" class="mb0">
                    <el-checkbox v-model="eventNameChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" align="right">
              <el-button
                type="primary"
                size="mini"
                :loading="listLoading"
                @click="searchListClick(1)"
                >In Browser</el-button
              >
              <el-button type="primary" size="mini" @click="exportExcelFileClick"
                >Download Excel</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          size="mini"
          stripe
          border
          ref="tableRef"
          :data="advReportList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
          highlight-current-row
        >
          <el-table-column
            label="Date"
            prop="date"
            v-if="monthChecked || dateChecked || hourChecked"
            width="100"
            align="center"
            key="date"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="eventName"
            prop="eventName"
            v-if="eventNameChecked"
            align="center"
            key="date"
          ></el-table-column>
          <el-table-column
            label="Source ID"
            prop="sourceId"
            v-if="sourceChecked"
            width="90"
            align="center"
            key="sourceId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            prop="sourceName"
            v-if="sourceChecked"
            width="110"
            align="center"
            key="sourceName"
          ></el-table-column>
          <el-table-column
            label="Offer ID"
            width="80"
            align="center"
            v-if="offerChecked"
            key="offerId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Name"
            width="100"
            align="center"
            v-if="offerChecked"
            key="offerName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Status"
            prop="offerStatus"
            width="105"
            align="center"
            v-if="offerChecked"
            key="offerStatus"
          ></el-table-column>
          <el-table-column
            label="Prod Name"
            prop="pkg"
            width="100"
            align="center"
            v-if="packageChecked"
            key="pkg"
          ></el-table-column>
          <el-table-column
            label="Country"
            prop="country"
            width="80"
            align="center"
            v-if="countriesChecked"
            key="country"
          ></el-table-column>
          <el-table-column
            label="Carrier"
            prop="carrier"
            width="80"
            align="center"
            v-if="carriersChecked"
            key="carrier"
          ></el-table-column>
          <el-table-column
            label="Category"
            prop="category"
            width="90"
            align="center"
            v-if="categoryChecked"
            key="category"
          ></el-table-column>
          <el-table-column
            label="Sources Manager"
            prop="sourceManager"
            width="90"
            align="center"
            v-if="sourceManagersChecked"
            key="sourceManager"
          >
            <template slot="header"> Sources<br />Manager </template>
          </el-table-column>
          <el-table-column
            label="pid"
            width="100"
            prop="pid"
            v-if="pidChecked"
            align="center"
            key="pid"
          ></el-table-column>
          <el-table-column
            label="Offer Link Type"
            width="100"
            prop="offerType"
            v-if="offerLinkTypeChecked"
            align="center"
            key="offerType"
          ></el-table-column>
          <el-table-column
            label="Conversions"
            min-width="110"
            align="center"
            show-overflow-tooltip
            key="conversions"
            prop="conversions"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.conversions),
                    filterNull(scope.row.lastConversions)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Payout"
            min-width="70"
            show-overflow-tooltip
            align="center"
            key="lastTotalPayout"
            prop="payout"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    scope.row.totalPayout,
                    new Number(scope.row.lastTotalPayout).toFixed(2)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Revenue"
            min-width="80"
            show-overflow-tooltip
            align="center"
            key="lastTotalRevenue"
            prop="revenue"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    scope.row.totalRevenue,
                    new Number(scope.row.lastTotalRevenue).toFixed(2)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Click Count"
            min-width="80"
            align="center"
            key="clickCount"
            prop="clickCount"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="Cvr"
            min-width="80"
            align="center"
            key="formatCvr"
            prop="formatCvr"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="installs"
            min-width="80"
            align="center"
            key="install"
            prop="install"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="insRejectTotal"
            min-width="80"
            align="center"
            prop="insRejectTotal"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="installRejectRate"
            min-width="80"
            align="center"
            prop="installRejectRate"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="clickFloods"
            min-width="80"
            align="center"
            prop="clickFloods"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="installHijackings"
            min-width="80"
            align="center"
            prop="installHijackings"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="bots"
            min-width="80"
            align="center"
            prop="bots"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="botsTimestamp"
            min-width="80"
            align="center"
            prop="botsTimestamp"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="botsBayesian"
            min-width="80"
            align="center"
            prop="botsBayesian"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="botsDevice"
            min-width="80"
            align="center"
            prop="botsDevice"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="behavioralAnomalies"
            min-width="80"
            align="center"
            prop="behavioralAnomalies"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="subReasonBehavioralAnomalies"
            min-width="80"
            align="center"
            prop="subReasonBehavioralAnomalies"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="siteBlacklists"
            min-width="80"
            align="center"
            prop="siteBlacklists"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="validationBots"
            min-width="80"
            align="center"
            prop="validationBots"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="botsFakeInstall"
            min-width="80"
            align="center"
            prop="botsFakeInstall"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="validationHijacking"
            min-width="80"
            align="center"
            prop="validationHijacking"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="ctitAnomalies"
            min-width="80"
            align="center"
            prop="ctitAnomalies"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="inappsBots"
            min-width="80"
            align="center"
            prop="inappsBots"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="anonymousTraffic"
            min-width="80"
            align="center"
            prop="anonymousTraffic"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="distributionOutlier"
            min-width="80"
            align="center"
            prop="distributionOutlier"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="tooManyEngagements"
            min-width="80"
            align="center"
            prop="tooManyEngagements"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="engagementInjection"
            min-width="80"
            align="center"
            prop="engagementInjection"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="incorrectSignature"
            min-width="80"
            align="center"
            prop="incorrectSignature"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="malformedAdvertisingId"
            min-width="80"
            align="center"
            prop="malformedAdvertisingId"
            sortable="custom"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import AdvReportCon from '../controllers/report/AdvReport';
  export default {
    name: 'AdvReport',
    ...AdvReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
