import { api, getAdvReport } from 'api/report';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { cloneDeep } from 'lodash-es';
import dateUtils from '@/utils/dateutils.js';
import qs from 'querystring';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { getPidsList } from 'api/product/rankSearch';
import { getPermissionAllListNew } from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      dataParam: null,
      offerChecked: false,
      collsourceFlag: false,
      countriesChecked: false,
      carriersChecked: false,
      packageChecked: false,
      categoryChecked: false,
      sourceChecked: false,
      monthChecked: false,
      eventNameChecked: false,
      dateChecked: true,
      hourChecked: false,
      sourceManagersChecked: false,
      pidChecked: false,
      offerLinkTypeChecked: false,
      startToEndDate: '',
      summariesMap: {},
      radioOptions: ['payout', 'revenue', 'conversions'],
      listLoading: false,
      total: null,
      advReportList: [],
      sourceManagers: [],
      timezoneOption: optionData.timezoneOption,
      categoryOption: optionData.categoryOption,
      fullWidth: document.documentElement.clientWidth,
      pidList: [],
      advReport: {
        countries: null,
        pkgs: null,
        sourceIds: null,
        offerIds: null,
        offerName: null,
        fromDate: null,
        toDate: null,
        timezone: '+00:00',
        columns: [],
        sorting: 'revenue',
        // comparing: true,
        carriers: null,
        category: null,
      },
    };
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
      listenChange() {
        const {
          sourceChecked,
          offerChecked,
          countriesChecked,
          eventNameChecked,
          packageChecked,
          sourceManagersChecked,
          monthChecked,
          dateChecked,
          hourChecked,
          pidChecked,
          offerLinkTypeChecked,
        } = this;
        return {
          sourceChecked,
          offerChecked,
          countriesChecked,
          eventNameChecked,
          packageChecked,
          sourceManagersChecked,
          monthChecked,
          dateChecked,
          hourChecked,
          pidChecked,
          offerLinkTypeChecked,
        };
      },
    }),
  },
  watch: {
    listenChange: function() {
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    this.managerList();
    this.getPids();
    this.getSourceIdsList();
  },
  methods: {
    ...mapActions('source', ['getSourceIdsList']),
    // 获取manager列表
    managerList() {
      getPermissionAllListNew().then((res) => {
        console.log(res);
        this.sourceManagers = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    showReportComparingItem(cur, last) {
      const pecent =
        cur == last
          ? '--'
          : 0 == last
          ? '100%'
          : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
      const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
      let result = cur;
      console.log(last);
      if (last != 0 && last != 'NaN') {
        result += '<span style="color:' + color + '">(' + last + ', ' + pecent + ')</span>';
      }
      return result;
    },
    filterNull(val) {
      return val == null ? 0 : val;
    },
    searchListClick(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      const param = {
        ...this.advReport,
        ...this.pageParam,
      };

      if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
        param.sourceIds = param.sourceIds.join(',');
      } else {
        delete param.sourceIds;
      }
      if (Array.isArray(param.categories) && param.categories.length > 0) {
        param.categories = param.categories.join(',');
      } else {
        delete param.categories;
      }
      if (this.offerChecked == true && param.columns.indexOf('offer_id') === -1) {
        param.columns.push('offer_id');
      } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
        const offerIdIndex = param.columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        param.columns.splice(offerIdIndex, 1);
      }
      if (
        this.sourceChecked == true &&
        param.columns.indexOf('source_id') === -1 &&
        param.columns.indexOf('source_name') === -1
      ) {
        param.columns.push('source_id');
        param.columns.push('source_name');
      } else if (
        this.sourceChecked == false &&
        param.columns.indexOf('source_id') !== -1 &&
        param.columns.indexOf('source_name') !== -1
      ) {
        const sourceIdIndex = param.columns.findIndex((item) => {
          if (item == 'source_id') {
            return true;
          }
        });
        param.columns.splice(sourceIdIndex, 1);
        const sourceNameIndex = param.columns.findIndex((item) => {
          if (item == 'source_name') {
            return true;
          }
        });
        param.columns.splice(sourceNameIndex, 1);
      }
      if (this.countriesChecked == true && param.columns.indexOf('country') === -1) {
        param.columns.push('country');
      } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
        const countryIndex = param.columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        param.columns.splice(countryIndex, 1);
      }
      if (this.carriersChecked == true && param.columns.indexOf('carrier') == -1) {
        param.columns.push('carrier');
      } else if (this.carriersChecked == false && param.columns.indexOf('carrier') !== -1) {
        const carrierIndex = param.columns.findIndex((item) => {
          if (item == 'carrier') {
            return true;
          }
        });
        param.columns.splice(carrierIndex, 1);
      }

      if (this.eventNameChecked == true && param.columns.indexOf('event_name') == -1) {
        param.columns.push('event_name');
      } else if (this.eventNameChecked == false && param.columns.indexOf('event_name') !== -1) {
        const eventNameIndex = param.columns.findIndex((item) => {
          if (item == 'event_name') {
            return true;
          }
        });
        param.columns.splice(eventNameIndex, 1);
      }

      if (this.packageChecked == true && param.columns.indexOf('prod') === -1) {
        param.columns.push('prod');
      } else if (this.packageChecked == false && param.columns.indexOf('prod') !== -1) {
        const prodIndex = param.columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        param.columns.splice(prodIndex, 1);
      }

      if (this.monthChecked == true && param.columns.indexOf('month') === -1) {
        param.columns.push('month');
      } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
        const monthIndex = param.columns.findIndex((item) => {
          if (item == 'month') {
            return true;
          }
        });
        param.columns.splice(monthIndex, 1);
      }

      if (this.dateChecked == true && param.columns.indexOf('date') === -1) {
        param.columns.push('date');
      } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
        const dateIndex = param.columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        param.columns.splice(dateIndex, 1);
      }

      if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
        param.columns.push('hour');
      } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
        const hourIndex = param.columns.findIndex((item) => {
          if (item == 'hour') {
            return true;
          }
        });
        param.columns.splice(hourIndex, 1);
      }
      if (this.sourceManagersChecked == true && param.columns.indexOf('source_manager') == -1) {
        param.columns.push('source_manager');
      } else if (
        this.sourceManagersChecked == false &&
        param.columns.indexOf('source_manager') !== -1
      ) {
        const sourceManagersIndex = param.columns.findIndex((item) => {
          if (item == 'source_manager') {
            return true;
          }
        });
        param.columns.splice(sourceManagersIndex, 1);
      }
      if (this.advReport.sourceManagers.length > 0) {
        param.sourceManagers = this.advReport.sourceManagers.toString();
      }
      if (this.pidChecked == true && param.columns.indexOf('pid') == -1) {
        param.columns.push('pid');
      } else if (this.pidChecked == false && param.columns.indexOf('pid') !== -1) {
        const pidIndex = param.columns.findIndex((item) => {
          if (item == 'pid') {
            return true;
          }
        });
        param.columns.splice(pidIndex, 1);
      }
      if (this.advReport.pids?.length > 0) {
        param.pids = this.advReport.pids.toString();
      }
      if (this.categoryChecked) {
        param.category = this.advReport.category;
      } else {
        param.category = null;
      }
      if (this.offerLinkTypeChecked == true && param.columns.indexOf('offer_type') == -1) {
        param.columns.push('offer_type');
      } else if (this.offerLinkTypeChecked == false && param.columns.indexOf('offer_type') !== -1) {
        const offerLinkTypeIndex = param.columns.findIndex((item) => {
          if (item == 'offer_type') {
            return true;
          }
        });
        param.columns.splice(offerLinkTypeIndex, 1);
      }
      if (this.advReport.offerTypes?.length > 0) {
        param.offerTypes = this.advReport.offerTypes.toString();
      }
      if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }
      this.pageParam.page = 1;

      const dataParam = cloneDeep(param);
      if (Array.isArray(dataParam.columns)) {
        dataParam.columns = dataParam.columns.join(',');
      }
      this.dataParam = dataParam;
      this.getAdvReport();
    },
    getAdvReport() {
      this.listLoading = true;
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      getAdvReport(param)
        .then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            if (response.result) {
              const summariesMap = response.result.pop() || [];
              const advReportList = response.result;
              this.advReportList = advReportList;
              this.summariesMap = summariesMap;
              this.total = response.total;

              const totalObject = new Object();
              totalObject.date = 'Total';
              totalObject.conversions = this.summariesMap.conversions;
              totalObject.affConversions = this.summariesMap.affConversions;
              totalObject.lastConversions = this.summariesMap.lastConversions;
              totalObject.lastTotalPayout = this.summariesMap.lastTotalPayout;
              totalObject.totalPayout = this.summariesMap.totalPayout;
              totalObject.totalRevenue = this.summariesMap.totalRevenue;
              totalObject.lastTotalRevenue = this.summariesMap.lastTotalRevenue;
              totalObject.clickCount = this.summariesMap.clickCount;
              totalObject.formatCvr = this.summariesMap.formatCvr;
              totalObject.install = this.summariesMap.install;
              totalObject.clickFloods = this.summariesMap.clickFloods;
              totalObject.installHijackings = this.summariesMap.installHijackings;
              totalObject.bots = this.summariesMap.bots;
              totalObject.botsTimestamp = this.summariesMap.botsTimestamp;
              totalObject.botsBayesian = this.summariesMap.botsBayesian;
              totalObject.botsDevice = this.summariesMap.botsDevice;
              totalObject.behavioralAnomalies = this.summariesMap.behavioralAnomalies;
              totalObject.siteBlacklists = this.summariesMap.siteBlacklists;
              totalObject.validationBots = this.summariesMap.validationBots;
              totalObject.botsFakeInstall = this.summariesMap.botsFakeInstall;
              totalObject.validationHijacking = this.summariesMap.validationHijacking;
              totalObject.ctitAnomalies = this.summariesMap.ctitAnomalies;
              totalObject.inappsBots = this.summariesMap.inappsBots;
              totalObject.anonymousTraffic = this.summariesMap.anonymousTraffic;
              totalObject.distributionOutlier = this.summariesMap.distributionOutlier;
              totalObject.tooManyEngagements = this.summariesMap.tooManyEngagements;
              totalObject.engagementInjection = this.summariesMap.engagementInjection;
              totalObject.incorrectSignature = this.summariesMap.incorrectSignature;
              totalObject.malformedAdvertisingId = this.summariesMap.malformedAdvertisingId;
              totalObject.insRejectTotal = this.summariesMap.insRejectTotal;
              totalObject.installRejectRate = this.summariesMap.installRejectRate;
              totalObject.subReasonBehavioralAnomalies = this.summariesMap.subReasonBehavioralAnomalies;
              this.advReportList.push(totalObject);
            } else {
              this.advReportList = [];
            }
          } else {
            this.advReportList = [];
            this.$message.error(response.message);
          }
        })
        .catch((e) => {
          this.listLoading = false;
          this.$message.error(e);
        });
    },
    exportExcelFileClick() {
      let param = {};
      let columns = new Array();

      if (
        this.sourceChecked == true &&
        columns.indexOf('source_id') === -1 &&
        columns.indexOf('source_name') === -1
      ) {
        columns.push('source_id');
        columns.push('source_name');
      } else if (
        this.sourceChecked == false &&
        columns.indexOf('source_id') !== -1 &&
        columns.indexOf('source_name') !== -1
      ) {
        const sourceIdIndex = columns.findIndex((item) => {
          if (item == 'source_id') {
            return true;
          }
        });
        columns.splice(sourceIdIndex, 1);
        const sourceNameIndex = columns.findIndex((item) => {
          if (item == 'source_name') {
            return true;
          }
        });
        columns.splice(sourceNameIndex, 1);
      }

      if (
        this.offerChecked == true &&
        // columns.indexOf('offerName') == -1 &&
        columns.indexOf('offer_id') == -1
      ) {
        columns.push('offer_id');
        // columns.push('offerName');
      } else if (
        this.offerChecked == false &&
        // columns.indexOf('offerName') !== -1 &&
        columns.indexOf('offer_id') !== -1
      ) {
        // const offerNameIndex = columns.findIndex((item) => {
        //   if (item == 'offerName') {
        //     return true;
        //   }
        // });
        const offerIdIndex = columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        // columns.splice(offerNameIndex, 1);
        columns.splice(offerIdIndex, 1);
      }

      if (this.countriesChecked == true && columns.indexOf('country') == -1) {
        columns.push('country');
      } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
        const countryIndex = columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        columns.splice(countryIndex, 1);
      }

      if (this.packageChecked == true && columns.indexOf('prod') == -1) {
        columns.push('prod');
      } else if (this.packageChecked == false && columns.indexOf('prod') !== -1) {
        const pkgIndex = columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        columns.splice(pkgIndex, 1);
      }

      if (this.carriersChecked == true && columns.indexOf('carrier') == -1) {
        columns.push('carrier');
      } else if (this.carriersChecked == false && columns.indexOf('carrier') !== -1) {
        const carrierIndex = columns.findIndex((item) => {
          if (item == 'carrier') {
            return true;
          }
        });
        columns.splice(carrierIndex, 1);
      }

      if (this.monthChecked == true && columns.indexOf('month') == -1) {
        columns.push('month');
      } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
        const monthIndex = columns.findIndex((item) => {
          if (item == 'month') {
            return true;
          }
        });
        columns.splice(monthIndex, 1);
      }

      if (this.dateChecked == true && columns.indexOf('date') == -1) {
        columns.push('date');
      } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
        const dateIndex = columns.findIndex((item) => {
          if (item == 'date') {
            return true;
          }
        });
        columns.splice(dateIndex, 1);
      }

      if (this.hourChecked == true && columns.indexOf('hour') == -1) {
        columns.push('hour');
      } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
        const hourIndex = columns.findIndex((item) => {
          if (item == 'hour') {
            return true;
          }
        });
        columns.splice(hourIndex, 1);
      }
      if (this.sourceManagersChecked == true && columns.indexOf('source_manager') == -1) {
        columns.push('source_manager');
      } else if (this.sourceManagersChecked == false && columns.indexOf('source_manager') !== -1) {
        const sourceManagersIndex = columns.findIndex((item) => {
          if (item == 'source_manager') {
            return true;
          }
        });
        columns.splice(sourceManagersIndex, 1);
      }
      if (this.pidChecked == true && columns.indexOf('pid') == -1) {
        columns.push('pid');
      } else if (this.pidChecked == false && columns.indexOf('pid') !== -1) {
        const pidIndex = columns.findIndex((item) => {
          if (item == 'pid') {
            return true;
          }
        });
        columns.splice(pidIndex, 1);
      }
      if (this.offerLinkTypeChecked == true && columns.indexOf('offer_type') == -1) {
        columns.push('offer_type');
      } else if (this.offerLinkTypeChecked == false && columns.indexOf('offer_type') !== -1) {
        const offerLinkTypeIndex = columns.findIndex((item) => {
          if (item == 'offer_type') {
            return true;
          }
        });
        columns.splice(offerLinkTypeIndex, 1);
      }
      if (this.eventNameChecked == true && columns.indexOf('event_name') == -1) {
        columns.push('event_name');
      } else if (this.eventNameChecked == false && columns.indexOf('event_name') !== -1) {
        const eventNameIndex = columns.findIndex((item) => {
          if (item == 'event_name') {
            return true;
          }
        });
        columns.splice(eventNameIndex, 1);
      }

      if (this.categoryChecked) {
        columns.category = this.advReport.category;
      } else {
        columns.category = null;
      }

      param['fromDate'] = this.startToEndDate[0];
      param['toDate'] = this.startToEndDate[1];
      if (this.advReport.timezone != null) {
        param['timezone'] = this.advReport.timezone;
      }

      if (this.advReport.sourceIds != null && this.advReport.sourceIds.length > 0) {
        param['sourceIds'] = this.advReport.sourceIds + '';
      }
      if (this.advReport.offerIds != null) {
        param['offerIds'] = this.advReport.offerIds;
      }
      if (this.advReport.countries != null) {
        param['countries'] = this.advReport.countries;
      }

      if (this.advReport.category != null && this.advReport.category.length > 0) {
        param['category'] = escape(this.advReport.category);
      }
      if (this.advReport.carriers != null) {
        param['carriers'] = this.advReport.carriers;
      }
      if (columns != null) {
        param['columns'] = columns.join(',');
      }
      if (this.advReport.offerName != null) {
        param['offerName'] = encodeURI(this.advReport.offerName);
      }
      if (this.advReport.sourceManagers.length > 0) {
        param['sourceManagers'] = this.advReport.sourceManagers.toString();
      }
      if (this.advReport.pids?.length > 0) {
        param['pids'] = this.advReport.pids.toString();
      }
      if (this.advReport.offerTypes?.length > 0) {
        param['offerTypes'] = this.advReport.offerTypes.toString();
      }
      let strParam = qs.stringify(param);
      console.log(api);
      window.open(`${api.DOWNLOAD_ADV_REPORT}?${strParam}`, '_blank');
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.getAdvReport();
    },
    offerdetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/offer/detail',
        query: {
          offerId: row.offerId,
        },
      });
      window.open(href, '_blank');
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.sourceId,
        },
      });
      window.open(href, '_blank');
    },
    // 排序监听
    sortChange(column) {
      console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.advReport.sorting = column.prop;
        this.advReport.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.advReport.sorting = '';
        this.advReport.sortType = '';
      }
      this.searchListClick('Search');
    },
  },
};
