import request from 'utils/request';

export const api = {
  // adv report
  ADV_REPORT: '/api/report/adv/list',
  DOWNLOAD_ADV_REPORT: '/api/report/adv/downloadExcel',
  // bill report
  BILL_REPORT: '/api/report/aff/list',
  DOWNLOAD_BILL_REPORT: '/api/report/aff/downloadExcel',
  HISTORY_DEDUCT: '/api/report/adv/downloadExcel',
  // postback report
  POSTBACK_REPORT: '/api/report/postback/list',
  POSTBACK_REPORT_NEW: '/api/report/postback/listWh',
  POSTBACK_REPORT_NEW_EXPORT: '/api/report/postback/exportPostBackReportWh',
  // commit report
  COMMIT_REPORT: '/api/report/common/list',
  DOWNLOAD_COMMIT_REPORT: '/api/report/common/export',

  // hot report
  HOT_REPORT: '/api/report/common/hot/list',
  DOWNLOAD_HOT_REPORT: '/api/report/common/hot/export',

  // block report
  BLOCK_REPORT: '/api/report/common/block/list',
  DOWNLOAD_BLOCK_REPORT: '/api/report/common/block/export',

  // conversion report
  CONVERSION_REPORT: '/api/report/conversion/list',
  DOWNLOAD_CONVERSION_REPORT: '/api/report/conversion/export',
  SEND_AFF_PB_CONVERSION_REPORT: '/api/report/conversion/sendAffPb',

  // click mix report
  MIX_REPORT_LIST: '/api/report/mix/list',
  MIX_CONFIG_LIST: '/api/report/mix/setting/list',
  ADD_MIX_CONFIG: '/api/report/mix/setting/add',
  DELETE_MIX_CONFIG: '/api/report/mix/setting/delete',

  DEFAULT_TRAFFIC_LIST: '/api/report/default/list',

  // Protect360
  PROTECT_360_LIST: '/api/protect360/list',

  // dataSource
  DATASOURCE_LIST: '/api/admin/mc/upload/result/all',

  // pdDomains
  REPORT_PDDOMAINS: '/api/report/common/domain/list',

  // tracksource report
  TRACKSOURCE_REPORT: '/api/report/clickSourceApp/list',
  DOWNLOAD_TRACKSOURCE_REPORT: '/api/report/clickSourceApp/export',

  //全回调数据统计
  ALL_PB_REPORT: '/api/admin/allpb/report/get',

  //CTIT report
  CTIT_REPORT: '/api/report/ctit/list',

  // appsflyer account
  APPSFLYER_ACCOUNT: '/api/adv/query',
  APPSFLYER_ACCOUNT_ADD: '/api/adv/insertAppsflyerAccount',
  APPSFLYER_ACCOUNT_Edit: '/api/adv/updateAppsflyerAccount',
  APPSFLYER_ACCOUNT_DETAILS: '/api/adv/getAppsflyerAccount/',
  APPSFLYER_ACCOUNT_DEL: '/api/adv/delete/',

  APPSFLYER_ACCOUNT_ALL_PIDAPPID: '/api/adv/selectAppPid/',

  // appsflyer report
  APPSFLYER_REPORT: '/api/adv/appflyer',
  DOWNLOAD_APPSFLYER_REPORT: '/api/report/appflyer/export',
  APPSFLYER_PA_REPORT: '/api/report/queryAppflyPaReport/list',
  DOWNLOAD_APPSFLYER_PA_REPORT: '/api/report/queryAppflyPaReport/export',
  APPSFLYER_PA_REPORT_RATE: '/api/report/queryAppflyPaReport/paRate',

  // appsflyer Agency
  APPSFLYER_AGENCY: '/api/adv/queryAgency',
  APPSFLYER_AGENCY_ADD: '/api/adv/insertAgency',
  APPSFLYER_AGENCY_EDIT: '/api/adv/updateAgency',
  APPSFLYER_AGENCY_DETAILS: '/api/adv/getAgency/',
  APPSFLYER_AGENCY_DEL: '/api/adv/deleteAgency/',

  // tracksource reportv2
  TRACKSOURCE_REPORTV2: '/api/admin/report/appSourceReport/list',
  DOWNLOAD_TRACKSOURCE_REPORTV2: '/api/admin/report/appSourceReport/export',

  // reject report
  REJECT_REPORT_LIST: '/api/report/listRejectReport',
  EXPORT_REJECT_REPORT_LIST: '/api/report/listRejectReport/export',
};

// 全回调数据统计
export function getAllpbReport(query) {
  return request({
    url: api.ALL_PB_REPORT,
    method: 'get',
    params: query,
  });
}

// adv report
export function getAdvReport(query) {
  return request({
    url: api.ADV_REPORT,
    method: 'get',
    params: query,
  });
}
// bill report
export function getBillReport(query) {
  return request({
    url: api.BILL_REPORT,
    method: 'get',
    params: query,
  });
}
export function getHistoryDeduct(data) {
  return request({
    url: api.HISTORY_DEDUCT,
    method: 'post',
    data,
  });
}
// postback report
export function getPostbackReport(query) {
  return request({
    url: api.POSTBACK_REPORT,
    method: 'get',
    params: query,
  });
}
// postback report new
export function getPostbackReportNew(query) {
  return request({
    url: api.POSTBACK_REPORT_NEW,
    method: 'get',
    params: query,
  });
}
// postback report new export
export function getPostbackReportNewExport(query) {
  return request({
    url: api.POSTBACK_REPORT_NEW_EXPORT,
    method: 'get',
    params: query,
  });
}
// commit report
export function getCommonReport(query) {
  return request({
    url: api.COMMIT_REPORT,
    method: 'get',
    params: query,
  });
}
// hot report
export function getHotReport(query) {
  return request({
    url: api.HOT_REPORT,
    method: 'get',
    params: query,
  });
}
// block report
export function getBlockReport(query) {
  return request({
    url: api.BLOCK_REPORT,
    method: 'get',
    params: query,
  });
}

// coversion report
export function getConversionReport(query) {
  return request({
    url: api.CONVERSION_REPORT,
    method: 'get',
    params: query,
  });
}
export function sendAffPb(ids) {
  return request({
    url: api.SEND_AFF_PB_CONVERSION_REPORT,
    method: 'put',
    data: { ids },
  });
}

// click mix report
export function getMixReportList(query) {
  return request({
    url: api.MIX_REPORT_LIST,
    method: 'get',
    params: query,
  });
}

export function getMixConfigList(query) {
  return request({
    url: api.MIX_CONFIG_LIST,
    method: 'get',
    params: query,
  });
}
export function addMixConfig(data) {
  return request({
    url: api.ADD_MIX_CONFIG,
    method: 'post',
    data,
  });
}
export function deleteConfig(ids) {
  return request({
    url: api.DELETE_MIX_CONFIG,
    method: 'delete',
    params: {
      ids,
    },
  });
}
//
export function getDefaultTrafficList(query) {
  return request({
    url: api.DEFAULT_TRAFFIC_LIST,
    method: 'get',
    params: query,
  });
}

// Protect360
export function fetchProtect360List(query) {
  return request({
    url: api.PROTECT_360_LIST,
    method: 'get',
    params: query,
  });
}

// dataSource
export function fetchDataSourceList(params) {
  return request({
    url: api.DATASOURCE_LIST,
    method: 'get',
    params,
  });
}

/**
 * 获取pb域名
 * @param params
 * @returns {*}
 */
export function fetchReportPdDomainsList(params) {
  return request({
    url: api.REPORT_PDDOMAINS,
    method: 'get',
    params,
  });
}

// tracksource report
export function getTracksourceReport(query) {
  return request({
    url: api.TRACKSOURCE_REPORT,
    method: 'get',
    params: query,
  });
}

// ctit report
export function getCTITReport(query) {
  return request({
    url: api.CTIT_REPORT,
    method: 'get',
    params: query,
  });
}

// AppsFlyerReport
export function getAppsFlyerReport(query) {
  return request({
    url: api.APPSFLYER_REPORT,
    method: 'get',
    params: query,
  });
}

export function getAppsFlyerPaReport(query) {
  return request({
    url: api.APPSFLYER_PA_REPORT,
    method: 'get',
    params: query,
  });
}

export function getAppsFlyerPaRate(query) {
  return request({
    url: api.APPSFLYER_PA_REPORT_RATE,
    method: 'get',
    params: query,
  });
}

// AppsFlyerReport pid->appid
export function getPidAppIdData() {
  return request({
    url: '/api/adv/selectPidApps',
    method: 'get',
  });
}

// AppsFlyerAccount列表
export function fetchAppsFlyerAccountPage(query) {
  return request({
    url: api.APPSFLYER_ACCOUNT,
    method: 'get',
    params: query,
  });
}

// AppsFlyerAccount添加
export function handleAppsFlyerAccountAdd(data) {
  return request({
    url: api.APPSFLYER_ACCOUNT_ADD,
    method: 'post',
    data,
  });
}

// AppsFlyerAccount更新
export function handleAppsFlyerAccountUpdate(data) {
  return request({
    url: api.APPSFLYER_ACCOUNT_Edit,
    method: 'put',
    data,
  });
}

// AppsFlyerAccount详情
export function fetchAppsFlyerAccountDetails(id) {
  return request({
    url: api.APPSFLYER_ACCOUNT_DETAILS + id,
    method: 'get',
  });
}

// AppsFlyerAccount删除
export function handleAppsFlyerAccountDel(id) {
  return request({
    url: api.APPSFLYER_ACCOUNT_DEL + id,
    method: 'DELETE',
  });
}
// AppsFlyerAccount PID所有列表
export function getAppsflyerAccountAllPidappid(query) {
  return request({
    url: api.APPSFLYER_ACCOUNT_ALL_PIDAPPID + query,
    method: 'get',
  });
}

// AppsFlyerAgency列表
export function fetchAppsFlyerAgencyPage(query) {
  return request({
    url: api.APPSFLYER_AGENCY,
    method: 'get',
    params: query,
  });
}

// AppsFlyerAccount添加
export function handleAppsFlyerAgencyAdd(data) {
  return request({
    url: api.APPSFLYER_AGENCY_ADD,
    method: 'post',
    data,
  });
}

// AppsFlyerAccount更新
export function handleAppsFlyerAgencyUpdate(data) {
  return request({
    url: api.APPSFLYER_AGENCY_EDIT,
    method: 'put',
    data,
  });
}

// AppsFlyerAccount详情
export function fetchAppsFlyerAgencyDetails(id) {
  return request({
    url: api.APPSFLYER_AGENCY_DETAILS + id,
    method: 'get',
  });
}

// AppsFlyerAccount删除
export function handleAppsFlyerAgencyDel(id) {
  return request({
    url: api.APPSFLYER_AGENCY_DEL + id,
    method: 'DELETE',
  });
}

// tracksource reportv2
export function getTracksourceReportv2(query) {
  return request({
    url: api.TRACKSOURCE_REPORTV2,
    method: 'get',
    params: query,
  });
}

// tracksource reportv2
export function getRejectReport(query) {
  return request({
    url: api.REJECT_REPORT_LIST,
    method: 'get',
    params: query,
  });
}
